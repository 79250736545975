import React from 'react'
import {
  NumericInput,
  CheckboxInput,
  Dropdown,
  FixedDropdown,
  DeleteItens,
} from 'components'

import {
  useCovenants,
  useTestPeriod,
  useTrainingPeriod,
  useProducts,
  useRule,
} from 'store'

import styles from './PrimarySimulationForm.module.scss'

const PrimarySimulationForm = () => {
  const {
    changeFormValue,
    getExclude,
    getHealthInsurance,
    getDailyNumbers,
    getTrainingDate,
    getTestDate,
    getReadjustment,
    getStopLoss,
    getMinAge,
    getSurgicalCare,
    addItemToDeleteList,
    removeItemFromDeleteList,
    checkErrors,
  } = useRule()

  const { getCovenants } = useCovenants()
  const { getTestPeriod } = useTestPeriod()
  const { getTrainingPeriod } = useTrainingPeriod()
  const { getProducts } = useProducts()
  const [search, setSearch] = React.useState('')
  React.useEffect(() => {
    checkErrors()
    // eslint-disable-next-line
  }, [])

  const changeValue = (element, value) => {
    changeFormValue(element, value)
  }

  const addItem = item => {
    return [addItemToDeleteList(item), setSearch('')]
  }

  const setDeletedList = i => {
    removeItemFromDeleteList(i)
  }

  return (
    <div>
      <div className={styles.simulationForm}>
        <div className={styles.textColumn}>
          <div className={styles.fieldContainer}>
            <p>Convênio</p>
            <div className={styles.field}>
              <Dropdown
                hasClear
                values={getCovenants()}
                search={getHealthInsurance()}
                setSearch={value => changeValue('healthInsurance', value)}
              />
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <p>Número de diárias</p>
            <div className={styles.field}>
              <NumericInput
                min={0}
                max={18}
                value={getDailyNumbers()}
                onChange={value => changeValue('dailyNumbers', value)}
                className={styles.item}
              />
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <p>Período treino</p>
            <div className={styles.field}>
              <FixedDropdown
                options={getTrainingPeriod()}
                selectedValue={getTrainingDate()}
                setSelectedValue={value => changeValue('trainingDate', value)}
              />
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <p>Período simulação</p>
            <div className={styles.field}>
              <FixedDropdown
                options={getTestPeriod()}
                selectedValue={getTestDate()}
                setSelectedValue={value => changeValue('testDate', value)}
              />
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <p>Reajuste (%)</p>
            <div className={styles.field}>
              <NumericInput
                min={0}
                max={100}
                value={getReadjustment()}
                onChange={value => changeValue('readjustment', value)}
                isPercent
                className={styles.item}
              />
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <p>Stop Loss</p>
            <div className={styles.field}>
              <NumericInput
                min={0}
                max={999999}
                value={getStopLoss()}
                onChange={value => changeValue('stopLoss', value)}
                className={styles.item}
              />
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <p>Idade mínima</p>
            <div className={styles.field}>
              <NumericInput
                min={0}
                max={18}
                value={getMinAge()}
                onChange={value => changeValue('minAge', value)}
                className={styles.item}
              />
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <p>Atendimento cirurgico</p>
            <div className={styles.field}>
              <CheckboxInput
                label="Incluir"
                isChecked={getSurgicalCare()}
                onClick={() => changeValue('surgicalCare', !getSurgicalCare())}
                hasLabel
              />
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <p>Excluir</p>
            <div className={styles.field}>
              <Dropdown
                allowEmpty
                values={getProducts().filter(
                  item => !getExclude().includes(item.value)
                )}
                search={search}
                setSearch={setSearch}
                addItem={item => addItem(item)}
                after3letters
              />
            </div>
          </div>
        </div>
      </div>
      {getExclude().length > 0 && (
        <p className={styles.smallText}>Itens excluidos</p>
      )}
      <DeleteItens
        deletedList={getExclude()}
        setDeletedList={i => setDeletedList(i)}
      />
    </div>
  )
}

export default PrimarySimulationForm
