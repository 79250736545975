import * as utils from 'lib/utils'
import * as providers from './provider'
import {
  parseFeatures,
  parseGroups,
  parseSectors,
  parseInclude,
  parsePercent,
} from './parsers'
import { initialValidation } from '../../templates/defaultValues'

export default (setRule, $rule) => {
  const changeValue = async (key, value) => {
    const { id: ruleId, workspaceId } = $rule

    setRule(prev => ({
      ...prev,
      [key]: value,
    }))

    const payload = {
      [key]: value,
    }

    await providers.changeRule(payload, workspaceId, ruleId)
  }

  const changeCanSimulate = value => {
    setRule(prev => ({
      ...prev,
      canSimulate: value,
    }))
  }

  const changeErrorMessage = value => {
    setRule(prev => ({
      ...prev,
      errorMessage: value,
    }))
  }

  const startLoading = () => {
    setRule(prev => ({
      ...prev,
      loading: true,
    }))
  }

  const endLoading = () => {
    setRule(prev => ({
      ...prev,
      loading: false,
    }))
  }

  const getDictSector = () => {
    const { itens } = $rule.parameters

    const response = {}
    if (itens) {
      itens.unMarkedGroups.forEach(item => {
        if (!response[item.sector]) {
          response[item.sector] = []
        }
        response[item.sector].push(item.groupId)
      })
    }
    return response
  }

  const changeFeatureValue = async features => {
    const { parameters } = $rule

    parameters.features = features

    changeValue('parameters', parameters)
  }

  const getCheckedFeatures = (featuresData, features) => {
    const newFeatureData = featuresData.map(feat => {
      const current = features.find(x => x.label === feat.label)

      return {
        ...feat,
        isChecked: current !== undefined ? current.isChecked : true,
      }
    })

    return newFeatureData
  }

  const getCheckedIncludes = (includesData, includes) => {
    const newIncludesData = includesData.map(feat => {
      const current = includes.find(x => x.groupId === feat.groupId)
      return {
        ...feat,
        isChecked: current !== undefined ? current.isChecked : feat.active,
      }
    })

    return newIncludesData
  }

  const fetchRule = async (workspaceId, ruleId) => {
    const [err, res] = await providers.getWorkspacesData(workspaceId, ruleId)
    if (err) {
      return
    }

    const { name, description, attributes, parameters, results } = res.data

    // TODO: tirar o if
    const [errF, resF] = await providers.getFeatures()
    if (errF) {
      return
    }

    res.data.parameters.features = getCheckedFeatures(
      parseFeatures(resF.data),
      parameters.features
    )

    const [errG, resG] = await providers.getGroups()

    if (errG) {
      return
    }

    parameters.itens.groups = parseGroups(resG.data)

    parameters.itens.include = getCheckedIncludes(
      parseInclude(parameters.itens.groups),
      parameters.itens.include
    )

    const [errS, resS] = await providers.getSectors()

    if (errS) {
      return
    }
    parameters.itens.sectors = parseSectors(resS.data)

    await setRule(prev => ({
      ...prev,
      loading: false,
      workspaceId,
      id: ruleId,
      name,
      description,
      attributes,
      parameters,
      results,
    }))
  }

  const simulate = async (
    { testPeriod, trainingPeriod, convenants },
    notLoad
  ) => {
    const {
      form: {
        dailyNumbers,
        trainingDate,
        testDate,
        stopLoss,
        minAge,
        surgicalCare,
        exclude,
        healthInsurance,
        readjustment,
      },
      itens: { include },
      features,
    } = $rule.parameters

    if (!notLoad) {
      startLoading()
    }

    await changeValue('attributes', null)

    changeErrorMessage('')

    const trainingDateKey = utils.getDropDownKey(trainingPeriod, trainingDate)
    const testDateKey = utils.getDropDownKey(testPeriod, testDate)
    const healthInsuranceKey = utils.getDropDownKey(
      convenants,
      healthInsurance,
      'value',
      'name'
    )

    const listedFeatures = features
      .filter(x => x.isChecked)
      .map(element => element.name)
    const parsedAlpha = parsePercent(readjustment)

    const body = {
      dataset: {
        filter_ids: exclude,
        include: utils.dropDownToDictionary(include.filter(x => x.isChecked)),
        dict_setor: getDictSector(),
      },
      simulation: {
        cd_convenio: healthInsuranceKey,
        min_age: parseInt(minAge, 10),
        include_atendimento_cirurgico: surgicalCare,
        dummies: listedFeatures,
        list_features: listedFeatures,
        qtd_diarias: parseFloat(dailyNumbers),
        stop_loss_value: parseFloat(stopLoss),
        stop_loss_type: 'diária',
        period: [trainingDateKey, testDateKey],
        alpha: parsedAlpha,
      },
    }

    if (process.env.REACT_APP_FEATURES_HASH) {
      body.dataset.features_hash = process.env.REACT_APP_FEATURES_HASH
    }

    if (process.env.REACT_APP_CONTA_ATUAL_HASH) {
      body.dataset.conta_atual_hash = process.env.REACT_APP_CONTA_ATUAL_HASH
    }

    const [err, res] = await providers.simulateRule(body, () => {
      endLoading()
      simulate({ testPeriod, trainingPeriod, convenants }, notLoad)
    })
    if (err) {
      changeErrorMessage(`An error has ocurred: ${err.message}`)
      endLoading()
      return
    }

    if (!notLoad) {
      endLoading()
    }

    const parsedData = utils.parseToJson(res.data)

    await changeValue('attributes', parsedData)
    await changeValue('results', parsedData)
  }

  // features
  const unMarkFeature = index => {
    const prevFeature = [...$rule.parameters.features]
    prevFeature[index].isChecked = !prevFeature[index].isChecked

    changeFeatureValue(prevFeature)
    changeCanSimulate(true)
  }

  // form
  const checkErrors = (data = $rule.parameters.form) => {
    const hasError = initialValidation.some(validation => {
      if (Array.isArray(validation)) {
        const [value, ...validations] = validation

        return (
          validations.some(valFunc => valFunc(data[value])) ||
          data[value].length === 0
        )
      }
      return data[validation].length === 0
    })
    changeCanSimulate(!hasError)
  }

  const changeFormValue = async (key, value) => {
    const { parameters } = $rule

    parameters.form[key] = value

    changeValue('parameters', parameters)
    checkErrors()
  }

  const addItemToDeleteList = item => {
    const { exclude } = $rule.parameters.form

    exclude.push(item)

    changeFormValue('exclude', exclude)
  }

  const removeItemFromDeleteList = index => {
    const { exclude } = $rule.parameters.form

    exclude.splice(index, 1)

    changeFormValue('exclude', exclude)
  }

  // itens
  const changeItemValue = async itens => {
    const { parameters } = $rule

    parameters.itens = itens

    changeValue('parameters', parameters)
    changeCanSimulate(true)
  }

  const createEnabledSelectors = async value => {
    const { itens } = $rule.parameters

    itens.enabledSectors = [...itens.enabledSectors, value]

    changeItemValue(itens)
  }

  const unmarkInclude = async i => {
    const { itens } = $rule.parameters

    const prevInclude = [...itens.include]
    prevInclude[i].isChecked = !prevInclude[i].isChecked
    itens.include = prevInclude

    changeItemValue(itens)
  }

  const unMarkSectorGroup = async (sector, groupId) => {
    const { itens } = $rule.parameters

    const isExcluded = itens.unMarkedGroups.some(
      unmarkedGroup =>
        unmarkedGroup.sector === sector && unmarkedGroup.groupId === groupId
    )

    itens.unMarkedGroups = !isExcluded
      ? [...itens.unMarkedGroups, { sector, groupId }]
      : itens.unMarkedGroups.filter(
          unmarkedGroup =>
            !(
              unmarkedGroup.groupId === groupId &&
              unmarkedGroup.sector === sector
            )
        )

    changeItemValue(itens)
  }

  const changeSelectedLeaf = value => {
    setRule(prev => ({
      ...prev,
      selectedLeafName: value.name,
      selectedLeafId:
        value.name === 'total' ? 'total' : value.name[value.name.length - 1],
    }))
  }

  return {
    changeValue,
    checkErrors,
    changeFormValue,
    fetchRule,
    simulate,
    addItemToDeleteList,
    removeItemFromDeleteList,
    unMarkFeature,
    createEnabledSelectors,
    unmarkInclude,
    unMarkSectorGroup,
    changeItemValue,
    changeSelectedLeaf,
    changeCanSimulate,
  }
}
