/* eslint-disable react/require-default-props */
import React from 'react'
import PropTypes from 'prop-types'

import { Form, Label } from 'semantic-ui-react'
import styles from './NumericInput.module.scss'

const NumericInput = ({
  min,
  max,
  value,
  onChange,
  onKeyUp,
  isPercent,
  allowEmpty,
}) => {
  const [label, setLabel] = React.useState(null)

  const handleChange = e => {
    const handleVariable = e.target.value.replace(/\D/g, '')
    onChange(handleVariable)
  }

  React.useEffect(() => {
    let message

    if (!allowEmpty && (!value || value === '')) {
      message = `Valor obrigatório`
    } else if (Number(value) > max) {
      message = `Valor máximo: ${max}`
    } else if (Number(value) < min) {
      message = `Valor minimo: ${min}`
    } else {
      setLabel(null)
      return
    }

    setLabel(
      <Label basic color="red" pointing="left" size="mini">
        {message}
      </Label>
    )
  }, [value, allowEmpty, max, min])

  return (
    <Form.Field>
      <input
        className={styles.input}
        type="text"
        value={value || ''}
        onChange={handleChange}
        onKeyUp={onKeyUp}
      />
      {label}
    </Form.Field>
  )
}

NumericInput.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onKeyUp: PropTypes.func,
  isPercent: PropTypes.bool,
  allowEmpty: PropTypes.bool,
}

NumericInput.defaultProps = {
  min: 0,
  max: 100,
  value: 1,
  isPercent: false,
  allowEmpty: false,
}

export default NumericInput
